import { Drawer, CardActionArea, Typography } from '@mui/material';
import Linely from '../../tools/Components/atoms/Linely';
import Box from '../../tools/Components/atoms/Box';
import FixedYBackground from '../../ui/widgets/FixedYBackground';
import logoBackground from '../../static/images/logo-background.svg'
import Centered from '../../tools/Components/atoms/Centered';
import logoImg from '../../static/images/logo.svg'
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import LuggageIcon from '@mui/icons-material/Luggage';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import { config } from '../../config';
import Icon from '../../ui/widgets/Icon';
import PathLink from '../../tools/Components/paths/PathLink';
import { useTranslation } from 'react-i18next';
import usePathRouter from '../../tools/hooks/paths/usePathRouter';

const pages = router => [
  {
    IconComponent: SportsKabaddiIcon,
    lTitle: 'Tourist.title',
    href: router.root.c.tourists.getUrl()
  },
  {
    IconComponent: LuggageIcon,
    lTitle: 'Tour.title',
    href: router.root.c.tours.getUrl()
  },
  config.isDevelopment && {
    IconComponent: CachedIcon,
    lTitle: 'Уютный дом Разраба',
    href: router.root.c.service.getUrl()
  },
  {
    IconComponent: ChatBubbleOutlineIcon,
    lTitle: 'Мессенджеры',
    href: router.root.c.messengers.getUrl()
  }

]
  .filter(el => !!el)

function LeftDrawer({ drawerAnchor, closeDrawer }) {
  const { t } = useTranslation()

  const [router] = usePathRouter()

  return (
    <Drawer
      anchor='left'
      open={drawerAnchor}
      onClose={closeDrawer}
    >
      <Box
        py='i1'
        w={350}
        position='relative'
        minHeight='100vh'
      >
        <FixedYBackground
          position='absolute'
          top={64}
          right={0}
          bottom={0}
          left={0}
          img={logoBackground}
          opacity={0.03}
          width={700}
          height={700}
          scale={2.374}
          rotate='5.9deg'
          translateX='0'
          translateY='0'
        />
        <Linely
          vertical
          fulLine
          gap='i1'
        >
          <Centered>
            <img
              src={logoImg}
              alt='logo'
            />
          </Centered>
          <Linely
            vertical
            fulLine
            gap={0}
          >
            {pages(router).map(({ lTitle, IconComponent, href }) => (
              <PathLink href={href} onClick={closeDrawer}>
                <CardActionArea>
                  <Linely p='i2' gap='i1'>
                    <Icon
                      Component={IconComponent}
                    />
                    <Typography variant='subtitle1'>
                      {t(lTitle)}
                    </Typography>
                  </Linely>
                </CardActionArea>
              </PathLink>
            ))}
          </Linely>
        </Linely>
      </Box>
    </Drawer>
  );
}

export default LeftDrawer;
