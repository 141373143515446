import "moment"
import moment, { Moment } from 'moment-timezone'

export const TZ_MOSCOW_UTC_OFFSET = 180 * 60

export const tsToMomentMoscow = (timestamp: number) => moment.tz(timestamp*1000, 'Europe/Moscow')

// просто переписывает часовой пояс оставляя как есть unix timestamp 
// пример: если в мск 3 ночи, а нам надо узнать сколько это же время в штатах
export const setMomentToMomentMoscow = (m: Moment) => moment.tz(m, 'Europe/Moscow')

// меняет unix timestamp так, чтобы в другом часовом поясе было столько же времени сколько в исходном
// пример: если в мск 3 ночи, а нам надо узнать через сколько времени в штатах тоже будет 3 часа ночи
export const transformMomentToMomentMoscow = (m: Moment) => moment.tz(
  m.clone().add((m.utcOffset() * 60) - TZ_MOSCOW_UTC_OFFSET, 'second'), 
  'Europe/Moscow'
)

export const getPersonYears = (m: Moment) => moment().diff(m, 'years', false)
