import api from '../../../../endpoints';
import router from '../../../../paths';
import { remove } from '../../../../tools/libs/paths/helpers';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import { Formik } from 'formik';
import WaitModalForm from './WaitModalForm';
import usePathParams from '../../../../tools/hooks/paths/usePathParams';
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import moment from 'moment';
import { setMomentToMomentMoscow } from '../../../../utils/moment';
import { waitFormSchema } from './waitFormSchema';

const CreateWait = ({ ...props }) => {
  const [{ createWait }] = usePathParams()
  const { tourId } = createWait || {}

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const onClose = useCallback(
    ({ id_tour, tourist }) => router.pushUrl(router.getUrl([
        [router.root.c.createWait, remove()],
        ...(id_tour ? [[router.root.c.tours.c.one.c.waits.c.one, { tourId: id_tour, touristId: tourist.id }]] : [])
      ]
    )),
    []
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { data, error } = await api.tree.reserves.create.request([
        tourId,
        values.touristId,
        {
          ...values,
          status_payment: 'wait_list',
          booking_date: values.booking_date?.unix()
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      onClose(data)
    },
    [tourId, onClose]
  )

  console.log('sd45rtf', tour)
  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Wait.createModal.title'
      lSubmitLable='Common.create'
      withoutBtns
    >
      <Formik
        initialValues={{
          booking_date: setMomentToMomentMoscow(moment())
        }}
        validationSchema={waitFormSchema}
        onSubmit={onSubmit}
      >
        {tour && (
          <WaitModalForm
            lSubmitLable='Common.create'
            onClose={onClose}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateWait
