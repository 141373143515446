import Typography from '@mui/material/Typography'
import TimeIcon from '@mui/icons-material//Schedule'
import Linely from '../../tools/Components/atoms/Linely'
import { setMomentToMomentMoscow } from '../../utils/moment'
import useCurrentTime from '../../tools/hooks/useCurrentTime'

function Time () {
  let [time] = useCurrentTime()
  time = setMomentToMomentMoscow(time)

  return (
    <Linely gap='i4'>
      <TimeIcon />
      <div>
        <Typography variant='body1' color='textSecondary'>
          {time.format('LT')} (МСК)
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          {time.format('L')}
        </Typography>
      </div>
    </Linely>
  )
}

export default Time
