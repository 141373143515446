import { PathNode, PathRouter } from '../tools/libs/paths'

const router = new PathRouter({}, {
  tourists: new PathNode({}, {
    one: new PathNode({ name: 'touristId', isParameter: true }, {}),
    tsSearch: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsSorted: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsSex: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsBirthdayBy: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsAgeStart: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsAgeEnd: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsBirthdayStart: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsBirthdayEnd: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsBirthdayMonthStart: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsBirthdayMonthEnd: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsTouristStatus: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsArchive: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tsPage: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
  }),
  tours: new PathNode({}, {
    one: new PathNode({ name: 'tourId', isParameter: true }, {
      reserves: new PathNode({}, {
        one: new PathNode({ name: 'touristId', isParameter: true }, {}),
        rSearch: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rSorted: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rSex: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rBirthdayBy: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rAgeStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rAgeEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rBirthdayStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rBirthdayEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rBirthdayMonthStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rBirthdayMonthEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rTouristStatus: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        rPage: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
      }),
      cancels: new PathNode({}, {
        one: new PathNode({ name: 'touristId', isParameter: true }, {}),
        cSearch: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cSorted: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cSex: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cAgeStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cAgeEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cTouristStatus: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        cPage: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
      }),
      waits: new PathNode({}, {
        one: new PathNode({ name: 'touristId', isParameter: true }, {}),
        wSearch: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wSorted: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wSex: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wBirthdayBy: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wAgeStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wAgeEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wBirthdayStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wBirthdayEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wBirthdayMonthStart: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wBirthdayMonthEnd: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wTouristStatus: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
        wPage: new PathNode({ optional: true }, {
          value: new PathNode({ name: 'value', isParameter: true })
        }),
      }),
    }),
    tSearch: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tSorted: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tSeasons: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tCustomDatesEnd: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tCustomDatesStart: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tCustomDates: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tComing: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tOver: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tCurrent: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tBusy: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tManagers: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tEscorts: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tArchive: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
    tPage: new PathNode({ optional: true }, {
      value: new PathNode({ name: 'value', isParameter: true })
    }),
  }),
  service: new PathNode(),
  login: new PathNode(),
  messengers: new PathNode(),
  createTourist: new PathNode({ optional: true }),
  editTourist: new PathNode({ optional: true }, {
    one: new PathNode({ name: 'touristId', isParameter: true }, {})
  }),
  createTour: new PathNode({ optional: true }),
  editTour: new PathNode({ optional: true }, {
    one: new PathNode({ name: 'tourId', isParameter: true }, {})
  }),
  createReserve: new PathNode({ optional: true }, {
    tour: new PathNode({ name: 'tourId', isParameter: true })
  }),
  editReserve: new PathNode({ optional: true }, {
    tour: new PathNode({ name: 'tourId', isParameter: true }, {
      tourist: new PathNode({ name: 'touristId', isParameter: true }, {})
    })
  }),
  createWait: new PathNode({ optional: true }, {
    tour: new PathNode({ name: 'tourId', isParameter: true })
  }),
  editWait: new PathNode({ optional: true }, {
    tour: new PathNode({ name: 'tourId', isParameter: true }, {
      tourist: new PathNode({ name: 'touristId', isParameter: true }, {})
    })
  }),
  editCancel: new PathNode({ optional: true }, {
    tour: new PathNode({ name: 'tourId', isParameter: true }, {
      tourist: new PathNode({ name: 'touristId', isParameter: true }, {})
    })
  }),
})

//@ts-ignore
window.router = router

export default router
