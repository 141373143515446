import React from 'react';

const MessengersPage = () => {
  const iframeUrl = `https://umnico.com/deals?umnico_plugin_id=${process.env.REACT_APP_MESSENGERS_PLUGIN_ID}&umnico_user_id=${process.env.REACT_APP_MESSENGERS_USER_EMAIL}`;
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="Мессенджеры"
        src={iframeUrl}
        />
    </div>
  );
};

export default MessengersPage;
